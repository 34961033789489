<template>
  <div class="userLogin">
    <div class="user">
      <div>
        <div class="login-title">
          <!-- <p class="versions">
            V.86
          </p> -->
          <div>
            <!-- <span>Đăng nhập</span> -->
            <!-- <span>Log in</span> -->
            <!-- <p>Tải APP ứng dụng</p> -->
            <!-- <p>v1.0.7</p> -->
          </div>
        </div>
        <div class="cont">
          <div
            v-for="(item, index) in formList"
            :key="index"
            class="border-bottom-calss"
          >
            <img :src="item.icon" />
            <div
              :style="{
                'justify-content':
                  item.key === 'userName' ? '' : 'space-between',
              }"
            >
              <span
                v-if="item.key === 'userName' && verConfig.areaCode"
                style="
                  padding-left: 15px;
                  padding-right: 12px;
                  border-right: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  color: #666;
                "
                >+{{ verConfig.areaCode }}</span
              >
              <input
                :type="item.type"
                :placeholder="item.placeholder"
                :maxlength="item.length"
                v-model="form[item.key]"
                :adjust-position="true"
                @focus="handleOnfocus"
                @blur="handleOnblur"
              />
              <!-- <img
                v-if="item.key === 'password'"
                src="@/assets/Img/login/icon/DL_0002_biyan.png"
                @click="handleLookPass"
              /> -->
              <img
                v-if="
                  (item.key === 'password' || item.key === 'againPassword') &&
                  item.flag === true
                "
                src="@/assets/icon/Lark20220424-175950.png"
                @click="handleLookPass"
              />
              <img
                v-if="
                  (item.key === 'password' || item.key === 'againPassword') &&
                  item.flag === false
                "
                src="@/assets/icon/Lark20220424-180000.png"
                @click="handleLookPass"
              />
            </div>
          </div>
          <van-checkbox v-model="checked" shape="round" class="checkbox"
            >Nhớ mật khẩu</van-checkbox
          >
          <van-button
            color="#e23d6d"
            size="normal"
            type="danger"
            @click="handleLogin"
            >Đăng nhập</van-button
          >
        </div>
        <div class="table">
          <div
            v-for="(item, index) in tablelist"
            :key="index"
            @click="handleTable(item.key)"
          >
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
      <!-- <div>
        <input type="text" v-model="message" />
        <button
          type="button"
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          Copy!
        </button>
      </div> -->
      <!-- <img class="middle" src="@/assets/Img/login/img/DL_0009_yuan3.png" />
      <img
        class="bottom"
        src="@/assets/Img/login/img/DL_0010_yuan2.png"
        v-if="isBottomIconShow"
      />
      <img class="top" src="@/assets/Img/login/img/DL_0011_yuan1.png" /> -->
      <!-- <img class="logo" src="@/assets/Img/login/img/Lark20220426-134910.png" /> -->
      <img class="logo" :src="require(`@/assets/ico/${ver}.png`)" />

      <!-- <div class="img-cont" v-if="isBottomIconShow">
        <img class="top" src="@/assets/Img/login/img/DL_tok.png" />
        <img class="top" src="@/assets/Img/login/img/DL_Qoo.png" />
        <img class="top" src="@/assets/Img/login/img/DL_buk.png" />
      </div> -->
    </div>
    <LoadingRE :show="LoadingShow" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Toast,
  Checkbox,
  CheckboxGroup,
  Dialog,
} from "vant";

// 设置vant的多语言
// import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import Modelu from "@/utils/Module";
import { getNewPhone } from "@/utils/tools";
// import { LOGIN } from "@/api";
import LoadingRE from "@/components/LoadingRE";
import { login } from "@/api/use.js";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    LoadingRE,
  },
  data() {
    return {
      LoadingShow: false,
      message: "copy",
      // country: Cookies.get("language"),
      form: {
        userName: "",
        password: "",
      },
      formList: [
        {
          placeholder: "Nhập số điện thoại của bạn",
          icon: require("@/assets/icon/login/userName.png"),
          key: "userName",
          type: "text",
          length: 99,
        },
        {
          placeholder: `Nhập mật khẩu của bạn`,
          icon: require("@/assets/icon/login/password.png"),
          key: "password",
          type: "password",
          length: 99,
          flag: false,
        },
      ],
      tablelist: [
        // {
        //   label: `Quên`,
        //   key: 'forgot',
        // },
        {
          label: `Đăng ký`,
          key: "registe",
        },
      ],
      isBottomIconShow: true,
      checked: false,
      thePwdObj: {
        userName: "",
        passwordL: "",
      },
      show: false,
    };
  },
  created() {
    this.init();
    this.isBottomIconShow = true;
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("token");
  },
  methods: {
    onCopy(e) {
      // console.log(e.text)
    },
    onError(e) {
      console.log("失败");
    },
    handleOnfocus() {
      this.isBottomIconShow = false;
      // console.log('focus', this.isBottomIconShow)
    },
    handleOnblur() {
      if (!this.isBottomIconShow) {
        this.isBottomIconShow = true;
      } else {
        setTimeout(() => {
          this.isBottomIconShow = true;
        }, 200);
      }
    },
    onSelect(e) {
      let _this = this;
      // _this.country = e.type;
      // console.log("country: ", country);
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
        case "idn":
          // window.console.log(e.type)
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
      }
      this.show = false;
    },
    onClose() {
      this.show = false;
    },
    /** handle **/
    handleLookPass() {
      if (this.formList[1].type === "password") {
        this.formList[1].type = "text";
        this.formList[1].flag = true;
      } else {
        this.formList[1].type = "password";
        this.formList[1].flag = false;
      }
    },
    async handleLogin() {
      const { userName: userphone, password: userpass } = this.form;
      if (!(userphone && userpass)) {
        Toast({
          message: "Xin vui lòng nhập một số điện thoại hợp lệ.",
          position: "bottom",
        });
        return;
      }
      this.$toast.loading({
        message: "logining...",
        forbidClick: true, // 控制背景色是否可点击
        duration: 0, // 延迟时间，从0到2秒之间
      });
      try {
        const {
          data: { ret, data },
        } = await login({ userphone, userpass });
        if (ret === 1) {
          this.$store.commit("setUser", data.token);
          this.$toast.success("đăng nhập thành công");
          this.$router.push("/my");
        } else {
          this.$toast.fail("Tài khoản hoặc mật khẩu không chính xác");
        }
      } catch (e) {
        console.log(e, "登录事件报错");
      }
    },
    handleTable(key) {
      this.$router.push(key);
    },
    /** API **/
    login() {
      let form = {
        userphone: this.form.userName,
        userpass: this.form.password,
      };
      this.LoadingShow = true;
      LOGIN(form) // http请求
        .then((r) => {
          this.$store.commit("SET_LOGIN_INFO", {
            phone: getNewPhone(this.form.userName),
            pwd: this.form.password,
            channel: Modelu.signStrFunc(),
          });
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            this.$cookie.set("the-pwd-state", this.checked);
            if (this.checked) {
              this.$cookie.set(
                "the-pwd-obj",
                JSON.stringify({
                  userName: this.form.userName,
                  password: this.form.password,
                })
              );
            } else {
              this.$cookie.remove("the-pwd-obj");
            }
          } else {
            this.LoadingShow = false;
            Toast(r.data.msg);
          }
          return r.data.ret;
        })
        .then((ret) => {
          if (ret === 1) {
            this.$api.getUserInfo(
              (res) => {},
              (err) => {},
              () => {
                this.LoadingShow = false;
                this.$router.replace({ name: "goods" });
              },
              this.$cookie.get("token")
            );
          }
        })
        .catch((e) => {
          this.LoadingShow = false;
          console.log(e);
        });
    },
    /** 其他 **/
    init() {
      let storePwdState = localStorage.getItem("the-pwd-state");
      let storePwd = localStorage.getItem("the-pwd-obj");
      let initObj = {
        userName: "",
        passwordL: "",
      };
      this.checked = storePwdState ? JSON.parse(storePwdState) : false;
      this.form = storePwd
        ? JSON.parse(storePwd)
          ? JSON.parse(storePwd)
          : initObj
        : initObj;
    },
  },
};
</script>

<style lang="less" scoped>
@checkbox-label-color: #88888d;
.userLogin {
  position: relative;
  width: 100%;
  height: calc(100vh - (110rem / 16));
  height: 100vh;
  background: #fff;
  font-size: 32px;
  /deep/ .van-button {
    background: var(--theme) !important;
    border: none;
  }
  .user {
    // margin-top: 110px * 2;
    padding-top: 110px * 2;
    > div {
      width: 85%;
      margin: auto;
      color: #000;
      > .login-title {
        margin-bottom: 70px * 2;
        > p {
          font-size: 12px * 2;
          line-height: 12px * 2;
          margin-bottom: 5px * 2;
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          > span {
            font-size: 24px * 2;
            line-height: 24px * 2;
            // border-right: 1px solid #cbcbcb;
            // padding-right: 5px * 2;
            // margin-right: 5px * 2;
          }
          > p {
            font-size: 12px * 2;
            line-height: 18px * 2;
          }
        }
      }
      > .cont {
        > .border-bottom-calss {
          border-bottom: 1px solid #cbcbcb;
        }
        > div {
          display: flex;
          align-items: center;
          padding: 20px 10px * 2;
          > img:nth-child(1) {
            width: 18px * 2;
            height: 18px * 2;
            margin-right: 8px * 2;
          }
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            > input {
              width: 220px * 2;
              border: 0;
              // border-left: 1px * 2 solid #cbcbcb;
              padding-left: 5px * 2;
              font-size: 15px * 2;
              color: #000;
            }
            > img:nth-last-child(1) {
              width: 30px * 2;
              margin-right: 15px * 2;
            }
          }
        }
        > div:nth-child(1) {
          margin-bottom: 30px * 2;
        }
        > button {
          width: 100%;
          // margin-top: 40px * 2;
          color: #ffffff;
          font-size: 15px * 2;
        }
      }
      > .table {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        margin-top: 34px * 2;
        > div {
          width: 50%;
          display: flex;
          justify-content: center;
          font-size: 14px * 2;
          > span {
            border-bottom: 1px solid #838383;
            padding-bottom: 16px * 2;
          }
        }
      }
    }
    > .top {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px * 2;
    }
    > .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 70px * 2;
    }
    > .middle {
      position: absolute;
      left: 0;
      top: 400px * 2;
      width: 10px * 2;
    }
    > .logo {
      // @size: 60px * 2;
      @size: 80px * 2;
      position: absolute;
      width: @size;
      height: @size;
      left: 50%;
      top: 40px * 2;
      transform: translate(-50%, 0);
    }
    > .img-cont {
      width: 90%;
      height: 50px * 2;
      display: flex;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      > img {
        width: 87px * 2;
        height: 28px * 2;
      }
    }
  }
  .checkbox {
    font-size: 12px * 2;
  }
  .van-checkbox__label {
    color: #88888d !important;
  }
}
</style>
