var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userLogin"},[_c('div',{staticClass:"user"},[_c('div',[_vm._m(0),_c('div',{staticClass:"cont"},[_vm._l((_vm.formList),function(item,index){return _c('div',{key:index,staticClass:"border-bottom-calss"},[_c('img',{attrs:{"src":item.icon}}),_c('div',{style:({
              'justify-content':
                item.key === 'userName' ? '' : 'space-between',
            })},[(item.key === 'userName' && _vm.verConfig.areaCode)?_c('span',{staticStyle:{"padding-left":"15px","padding-right":"12px","border-right":"1px solid #ccc","border-left":"1px solid #ccc","color":"#666"}},[_vm._v("+"+_vm._s(_vm.verConfig.areaCode))]):_vm._e(),((item.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[item.key]),expression:"form[item.key]"}],attrs:{"placeholder":item.placeholder,"maxlength":item.length,"adjust-position":true,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form[item.key])?_vm._i(_vm.form[item.key],null)>-1:(_vm.form[item.key])},on:{"focus":_vm.handleOnfocus,"blur":_vm.handleOnblur,"change":function($event){var $$a=_vm.form[item.key],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, item.key, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, item.key, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, item.key, $$c)}}}}):((item.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[item.key]),expression:"form[item.key]"}],attrs:{"placeholder":item.placeholder,"maxlength":item.length,"adjust-position":true,"type":"radio"},domProps:{"checked":_vm._q(_vm.form[item.key],null)},on:{"focus":_vm.handleOnfocus,"blur":_vm.handleOnblur,"change":function($event){return _vm.$set(_vm.form, item.key, null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[item.key]),expression:"form[item.key]"}],attrs:{"placeholder":item.placeholder,"maxlength":item.length,"adjust-position":true,"type":item.type},domProps:{"value":(_vm.form[item.key])},on:{"focus":_vm.handleOnfocus,"blur":_vm.handleOnblur,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, item.key, $event.target.value)}}}),(
                (item.key === 'password' || item.key === 'againPassword') &&
                item.flag === true
              )?_c('img',{attrs:{"src":require("@/assets/icon/Lark20220424-175950.png")},on:{"click":_vm.handleLookPass}}):_vm._e(),(
                (item.key === 'password' || item.key === 'againPassword') &&
                item.flag === false
              )?_c('img',{attrs:{"src":require("@/assets/icon/Lark20220424-180000.png")},on:{"click":_vm.handleLookPass}}):_vm._e()])])}),_c('van-checkbox',{staticClass:"checkbox",attrs:{"shape":"round"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("Nhớ mật khẩu")]),_c('van-button',{attrs:{"color":"#e23d6d","size":"normal","type":"danger"},on:{"click":_vm.handleLogin}},[_vm._v("Đăng nhập")])],2),_c('div',{staticClass:"table"},_vm._l((_vm.tablelist),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleTable(item.key)}}},[_c('span',[_vm._v(_vm._s(item.label))])])}),0)]),_c('img',{staticClass:"logo",attrs:{"src":require(("@/assets/ico/" + _vm.ver + ".png"))}})]),_c('LoadingRE',{attrs:{"show":_vm.LoadingShow}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-title"},[_c('div')])}]

export { render, staticRenderFns }