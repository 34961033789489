<template>
  <!-- 登录组件 -->
  <div class="my-container">
    <template v-if="!verConfig.useNewLogin">
      <van-nav-bar title="đăng nhập member">
        <i
          slot="left"
          class="iconfont icon-jiantou"
          :color="!$isLinkGame ? '#ffd400' : '#fff'"
          @click="$router.back()"
          size="50px"
        ></i>
      </van-nav-bar>
      <div class="new-form">
        <div class="form-item" v-for="(item, index) in formList" :key="index">
          <span>{{ item.label }}</span>
          <input
            :type="item.type"
            :placeholder="item.placeholder"
            v-model="userinfo[item.key]"
          />
          <p class="num-absolute" v-if="!index">+84</p>
        </div>
        <p class="forget-pwd" @click="goservice()">Quên mật khẩu?</p>
        <button
          class="login-btn"
          :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
          @click="onSubmit"
        >
          đăng nhập
        </button>
        <div class="link-pages-box">
          <p v-if="fromid" @click="$router.push(`/registe?id=${fromid}`)">
            đăng ký một tài khoản
          </p>
          <p v-else @click="$router.push('/registe')">đăng ký một tài khoản</p>
          <!-- <p @click="handleLinkPages">ทดลองใช้ฟรี</p> -->
        </div>
      </div>
    </template>
    <Newlogin v-else />
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { login } from '@/api/use.js'
import '../my.less'
import { downloadApp } from '@/utils/downloadUrl'
import { isAppIos } from '@/utils/unique'
import Newlogin from "@/views/login/components/newLogin.vue"

export default {
  name: 'Login',
  components: { Newlogin },
  data() {
    return {
      userinfo: {
        userphone: '',
        userpass: '',
      },
      fromid: '',
      formList: [
        {
          label: 'số điện thoại',
          type: 'number',
          placeholder: 'Xin vui lòng điền số điện thoại của bạn.!',
          key: 'userphone',
        },
        {
          label: 'Mật khẩu',
          type: 'password',
          placeholder: 'Xin hãy điền mật khẩu.',
          key: 'userpass',
        },
      ],
    }
  },
  mounted() {},
  created() {
    if (localStorage.getItem('fromid')) {
      this.fromid = localStorage.getItem('fromid')
    }
  },
  methods: {
    // 跳转客服
    goservice() {
      window.location.href = localStorage.getItem('serviceURL')
    },
    //   1、处理登录的函数
    async onSubmit() {
      const { userphone, userpass } = this.userinfo
      if (!(userphone && userpass)) {
        Toast({
          message: 'Xin vui lòng nhập một số điện thoại hợp lệ.',
          position: 'bottom',
        })
      } else {
        this.$toast.loading({
          message: 'logining...',
          forbidClick: true, // 控制背景色是否可点击
          duration: 0, // 延迟时间，从0到2秒之间
        })
        try {
          const {
            data: { ret, data },
          } = await login(this.userinfo)
          if (ret === 1) {
            this.$store.commit('setUser', data.token)
            this.$toast.success('đăng nhập thành công')
            this.$router.push('/my')
          } else {
            this.$toast.fail('Tài khoản hoặc mật khẩu không chính xác')
          }
        } catch (e) {}
      }
    },
    handleLinkPages() {
      Dialog.confirm({
        title: 'dấu hiệu',
        message:
          'Phiên bản web không có chức năng này. Vui lòng chuyển đến phiên bản ứng dụng tải xuống.',
        cancelButtonText: 'Hủy bỏ',
        confirmButtonText: 'xác nhận',
      })
        .then(() => {
          if (isAppIos()) {
            this.$toast('APP chỉ hỗ trợ người dùng Android tải xuống!')
            return false
          }
          window.location.href =
            localStorage.getItem('appurl_android') || downloadApp
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style lang="less" scoped>
.new-form {
  width: 100%;
  height: calc(100% - 92px);
  background-color: #fff;
  > .form-item {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    > span {
      font-size: 36px;
      color: #323233;
      margin-bottom: 15px;
    }
    > input {
      // width: calc(100% - 220px);
      font-size: 36px;
      color: #323233;
      border: 0;
    }
  }
  > .form-item:nth-child(1) {
    padding-top: 50px;
    > input {
      padding-left: 70px;
    }
    > .num-absolute {
      position: absolute;
      font-size: 36px;
      color: #323233;
      bottom: 32px;
    }
  }
  > .forget-pwd {
    height: 70px;
    line-height: 70px;
    font-size: 28px;
    text-align: right;
    margin-right: 30px;
    color: #757575;
  }
  > .login-btn {
    width: 90%;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    background-color: #ff0000;
    color: #fff;
    border: 0;
    border-radius: 5px;
    margin: auto;
    display: block;
    text-align: center;
  }
  > .link-pages-box {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #757575;
    margin-top: 70px;
    > p {
      width: 100%;
      line-height: 30px;
      text-align: center;
    }
    > p:nth-child(1) {
      border-right: 1px solid #e4e4e4;
    }
  }
}
</style>
